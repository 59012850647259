/* this file will be extracted to main dist folder and is imported in index.html */
/* This file is for setting global styles  */

@import '../../node_modules/@apiax/web-commons/src/styles/_commons_colors.scss';
@import '../../node_modules/@apiax/web-commons/src/styles/index';
@import '../../node_modules/@apiax/web-commons/src/common-assets/flags/flags.css';

*,
*::before,
*::after {
  box-sizing: border-box;
}
